// TuyaIntegration.scss
.tuya-integration {
  padding: 1rem;

  h2, h3 {
    color: #007bff;
  }

  p, li {
    color: #333;
  }

  ol {
    margin-left: 20px;
  }

  // Additional styling as needed
}
