// pricing.scss
.pricing {
  text-align: center;
  padding: 2rem;

  .pricing-details {
    margin-top: 1rem;

    .highlight {
      color: #28a745; // Color for highlighting the free aspect
      font-weight: bold;
    }

    .future-pricing {
      margin-top: 2rem;
      border-top: 1px solid #ddd;
      padding-top: 1rem;
    }
  }
}
