// integrations.scss
.integrations {
  text-align: center;
  padding: 2rem;

  .integration-list {
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    .integration {
      margin: 0 1rem;
      padding: 1rem;
      border: 1px solid #ddd;

      h3 {
        margin-bottom: 0.5rem;
      }

      a {
        color: #007bff;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .coming-soon {
    margin-top: 2rem;
    font-style: italic;
  }
}
