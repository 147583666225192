// hero.scss
.hero {
  background-color: #007bff; // Example background color
  color: white;
  padding: 2rem;
  text-align: center;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
  }

  // Add more styles as needed for buttons, images, etc.
}
