// index.scss

// Importing Bootstrap SCSS for customization
@import 'bootstrap/scss/bootstrap';

// Define your own variables, mixins, or functions here
$base-font-family: 'Arial', sans-serif;
$base-font-size: 16px;
$primary-color: #007bff;
$secondary-color: #6c757d;

// Global style reset or modifications
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $base-font-family;
  font-size: $base-font-size;
  color: $secondary-color;
  background-color: #f8f9fa;
}

// Global container styles
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

// Additional global styles
