// donation.scss
.donation {
  text-align: center;
  padding: 2rem;

  button {
    background-color: #007bff;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: darken(#007bff, 10%);
    }
  }
}
